.login-header-button,
.login-header-button:focus,
.login-header-button:active {
  background-color: transparent;
  border: 2px solid var(--gold);
  color: var(--gold);
  border-radius: 20px;
  height: 40px;
  font-size: 16px;
  font-weight: bold;
  padding: 0 25px 0 25px;
  display: flex;
  align-items: center;
}
.public_page_wrapper.login {
  display: flex;
  flex-direction: column;
  max-width: 1140px;
  align-items: center;
}
.public_page_wrapper.login h1 {
  margin: 0 0 40px;
  font-size: 3.25rem;
  font-weight: 700;
  line-height: 4.375rem;
  color: white;
  font-family: Poppins,sans-serif;
  max-width: 780px;
  text-align: center;
}
.public_page_wrapper.login h1 em {
  color: #cda768;
  font-style: normal;
}

.login_overlay {
  width: 80%;
  max-width: 700px;
  display: flex;
  justify-content: center;
  background: transparent;
  border: 0;
  height: 150vh;
  min-height: 200px;
}
.login_overlay .ant-card-body {
  width: 100%;
  max-width: 500px;
}

.login_form {
  width: 100%;
}
.login_form .ant-input {
  height: 35px;
  width: 100%;
}
.login_form .ant-btn {
  margin-top: 10px;
  height: 50px;
  width: 100%;
  text-transform: uppercase;
  font-size: 20px;
  /* font-family: var(--orbitron_font); */
  border: 0 solid var(--gold);
  background: var(--gold);
  border-radius: 25px;
  color: white;
}
.login_form .ant-form-item-label > label {
  color: white;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 1.5em;
}
.login_form .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: '';
}
.login_form .ant-btn,
.login_form .ant-btn:focus,
.login_form .ant-btn:active {
  background-color: var(--gold);
  border: 3px solid var(--gold);
}
.login_form .ant-btn:hover {
  background-color: white;
  border: 0 solid var(--gold);
  color: var(--gold);
}


.login_form .login_input_text .ant-input-affix-wrapper {
  border: 1px solid #565656;
  border-radius: .1875rem;
  padding: 15px;
}
.login_form .login_input_text .ant-input-affix-wrapper,
.login_form .login_input_text .ant-input-affix-wrapper input {
  background-color: #373737;
  color: #fff;  
}
.version {
  color: #fff;
  text-align: center;
  margin-top: 0;
  padding-top: 0;
  font-size: 14px;
}
