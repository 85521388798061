.merge_page_wrapper .sub_header_action .btn_add {
  margin-left: 20px;
}

.merge_page_wrapper .notifiations {
    list-style: none;
}

.merge_page_wrapper .notifiations .error {
    color: red;
    font-weight: bolder;
}
  

.tracker_fusion_list .ant-table-thead .ant-table-cell,
.tracker_fusion_list .ant-table-tbody .ant-table-cell {
 text-align: center;
}

.tracker_fusion_list .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
  padding: 3px;
}

#trackers-status-list .live_requested {
    background-color: lightyellow;
}

#trackers-status-list .live {
    background-color: #e5fbe5;
}
#trackers-supervision-list .live{
    background-color: #e9dcb1;
}

#trackers-supervision-list .in_charge {
    background-color: #d7d7d7;
    color: black;
}

#trackers-status-list span.action,
#merge-trackers-list span.action{
    padding-left: 10px;
    padding-right: 10px;
}

.sub_header_action {
    margin-left: 30px;
    margin-right: 30px;
}

#tracker_state,
#tracker_fusion{
    margin-bottom: 20px;
}

.ant-tabs-tab {
    margin-left: 20px;
    font-size: 20px;
}


#trackers-status-list,
#trackers-supervision-list,
#merge-trackers-list {
    padding: 20px;
}